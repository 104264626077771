var armLanguage = {
    callButon: 'Պատվիրել զանգ',
    number: '+374-77(62-65-79)',
    menu1: 'ՄԵՏԱՂԱՊԼԱՍՏԵ',
    menu2: 'ԱԼՅՈՒՄԻՆԵ',
    menu3: 'ԱՊԱԿՅԱ ԿՈՆՍՏՐՈՒԿՑԻԱՆԵՐ',
    marquee: 'Ամառային շահավետ առաջարկներ SHINPROF-ից 10քմ և ավել պատվերի դեպքում նվեր՝ ԲԱՐԴ ՓԱԿԱՆ ԿԱՄ ՑԱՆՑ ԱՊԱՌԻԿԸ ՏԵՂՈՒՄ 0% ԿԱՆԽԱՎՃԱՐՈՎ ՉԱՓԱԳՐՈՒՄԸ և ՏԵՂԱԴՐՈՒՄԸ ԱՆՎՃԱՐ',
    sec2Tit: 'Մետաղապլաստե Պատուհաններ ՇԻՆՊՐՈՖ - Ից',
    sec2Par: 'Այսօր ամբողջ աշխարհում շատ տարածված է պլաստիկ պատուհանների տեղադրումը: Սա ոչ միայն նորաձև է, այլև`հարմար գործածության համար: Եվրոպատուհանները համապատասխանում են որակի և հիգիենայի միջազգային բոլոր չափանիշներին, ինչը հնարավոր է դարձնում դրանց օգտագործումը բոլոր տեսակի տարածքների համար: Մարդիկ ամբողջ աշխարհում նախապատվությունը տալիս են մետաղապլաստե պատուհաններին, քանի որ դրանք ունեն բազմաթիվ առավելություններ',
    sec2Stat1: 'ՀԱՐՄԱՐ ԲՈԼՈՐ ԵՂԱՆԱԿՆԵՐԻՆ',
    sec2Stat2: 'ՊԱՇՏՊԱՆՈՒՄ ՁԱՅՆԵՐԻՑ',
    sec2Stat3: 'ՊԱՌԶ ԵՒ ԱՊԱՀՈՎ',
    sec3Tit: 'Պատուհաններ գրպանին հարմար: սկսած 19.000 դր.',
    sec3Par: 'Պատվիրեք հիմա պատուհան, իսկ ավելի ուշ վճարեք: Տրամադրվում է բանկերի կողմից:',
    sec3ParStr: 'ԱԿԲԱ-ԿՐԵԴԻՏ ԱԳՐԻԿՈԼ.',
    sec4MainTit: 'ՄԵՐ ԱՐՏԱԴՐԱՆՔԻ ԲԱՐՁՐ ՈՐԱԿԸ',
    sec4Tit1: 'Պրոֆիլային Համակարգ',
    sec4par1: 'Երկարակեցություն, լավ պաշտպանիչ հատկություններ, գերմանական պրոֆիլային համակարգի գների և որակի օպտիմալ հարաբերակցություն, xxxx- ն առավելագույնս հարմար է հուսալի տաք պատուհանների արտադրության համար:',
    sec4Tit2: 'Երկկողմանի Պատուհան',
    sec4par2: 'երկկողմանի պատուհանների մեծ տեսականի, հնարավորություն է տալիս ընտրել ցանկության արտադրանք: ցանկացած լուծումներ մատչելի են ձեզ համար, ներառյալ ուժեղացված պաշտպանությունը աղմուկից, ցրտից և ամռան տապից:',
    sec4Tit3: 'Կցամասեր',
    sec4par3: 'Պլաստիկ պատուհանների գործառույթը, հարմարավետությունը և օգտագործման հեշտությունը կախված են կցամասերի որակից: Մենք օգտագործում ենք ավստրիական MACO ընկերության հուսալի կցամասեր',
    sec5MainTit: 'ՀԱՏՈՒԿ ԱՌԱՋԱՐԿ: ՎԱՐԿԻ ՁԵՎԱԿԵՐՊՈՒՄ!',
    sec5StatTit1: 'ընդամենը 20 րոպեյում',
    sec5StatPar1: 'վարկի ձեվակերպում',
    sec5StatTit2: 'սկսած 0%',
    sec5StatPar2: 'Կանխավճար',
    sec5StatTit3: 'ժամկետ',
    sec5StatPar3: 'մինչև 24 ամիս',
    sec5ContTit: 'թողեք չափագրման հայտ և մեր մասնագետը կմոտենա անվճար',
    sec5ContNum1: '+374-77(62-65-79)',
    sec5ContNum2: '+374-77(62-65-79)',
    sec5CallBut: 'ՊԱՏՎԻՐԵԼ ԶԱՆԳ',
    sec6Tit: 'ՊԼԱՍՏԻԿ ՊԱՏՈՒՀԱՆՆԵՐԻ ԿՑԱՄԱՍԵՐ',
    sec6SlideTit1: 'Պատուհանագոգեր',
    sec6SlideTit2: 'բացման սահմանափակիչներ',
    sec6SlideTit3: 'Մոծակների ցանցեր',
    sec6SlideTit4: 'Lorem ipsum dolor',
    sec6SlideTit5: 'բռնակներ',
    sec6SlideTit6 : 'Lorem ipsum dolor',
    sec6SlidePar1: 'ցանկացած ինտերիերի և ցանկացած ֆունկցիոնալության համար նյութերի, չափերի, գույների և հյուսվածքների մեծ ընտրություն: Առկա են մեխանիկական և քիմիական գործոններով դիմադրողականության բարձրացված պատուհանագոգեր:',
    sec6SlidePar2: 'Տանը և երեխաների խնամքի հաստատություններում անվտանգությունն ապահովելու համար մենք առաջարկում ենք պատուհանները սարքավորել կողպեքներով և բացման կանգառներով: Սարքերը կարող են տեղադրվել արդեն տեղադրված պատուհանների վրա:',
    sec6SlidePar3: 'ցանցերի հսկայական տեսականին թույլ է տալիս ընտրել լավագույն տարբերակը ՝ հաշվի առնելով տան բնակիչների կարիքները: Ձեր ընտանիքը մաքուր և առողջ պահելու համար կան ցանցեր, որոնք ունեն միկրո-աղտոտիչները և փոշոտումը ֆիլտրելու գործառույթը:',
    sec6SlidePar4: '',
    sec6SlidePar5: 'Պատուհանի բռնակների մեծ տեսականին թույլ է տալիս ընտրել հարմար տարբերակ, որը պատված է պատուհանի և ինտերիերի գույնով և ոճով: Բռնակի մաշկի դիմացկուն ծածկույթը դիմակայելու է երկար տարիների ինտենսիվ օգտագործմանն ու կպահպանի իր սկզբնական տեսքը:',
    sec6SlidePar6: '',
    sec7MainTit: 'ԻՆՉՈՒ ԸՆՏՐԵԼ ՇԻՆՊՐՈՖ',
    sec7Tit1: 'ՄԵՆԵՋԵՐ',
    sec7Tit2: 'ԱՆՎՃԱՐ ՄԱՍՆԱԳԵՏՆԵՐԻ ԱՅՑ',
    sec7Tit3: 'ԺԱՄԱՆԱԿԻ ԽՆԱՅՈՂՈՒԹՅՈՒՆ',
    sec7Tit4: 'ՓՈՐՁԱԳԻՏԱԿԱՆ ​​ԽՈՐՀՐԴԱՏՎՈՒԹՅՈՒՆ',
    sec7Tit5: 'ԾԱՎԱԼԻ ԶԵՂՉ',
    sec7Tit6: 'ԱՊԱՌԻԿ ՎՃԱՐՈՒՄ',
    sec7Par1: 'Ձեր նախագծի համար պատասխանատու կլինի մեկ մենեջեր, որին կարող եք տալ բոլոր հարցերը:',
    sec7Par2: 'վարպետը կժամանի նշված հասցեով, կհստակեցնի պատվերի բոլոր մանրամասները և դուրս կբերի ճշգրիտ չափերը:',
    sec7Par3: 'Ստորագրեք պայմանագիրը և վճարեք այնտեղ, որտեղ դա հարմար է ձեզ համար `մեր գրասենյակում կամ տանը:',
    sec7Par4: 'Մենք ձեզ բոլորիդ կպատմենք ապակեպատման մասին և կտանք դիզայնի առաջարկություններ:',
    sec7Par5: 'Մինչև xx% զեղչ ամենահետաքրքիր լուծումների և լայնածավալ ապակեպատման ծավալների վրա:',
    sec7Par6: 'Մի սահմանափակեք ձեր հնարավորությունները. 3, 6, 12 ամսվա ընթացքում օգտագործեք ապառիկ:',
    copyright: '©2022 Բոլոր իրավունքները պաշտպանված են |',
    copyrightLink: 'Digicode',
}
export default armLanguage;