var engLanguage = {
    callButon: 'REQUEST A CALL',
    number: '+374-77(62-65-79)',
    menu1: 'METAL PLASTIC',
    menu2: 'ALUMINUM',
    menu3: 'GLASS CONSTRUCTIONS',
    marquee: 'Great summer deals from shinprof When ordering more than 10 square meters as a gift: COMPLEX LOCK OR NETWORK. INSTALLMENT LOAN 0% ADVANCE, MEASURING AND INSTALLATION FREE OF CHARGE',
    sec2Tit: 'Plastic windows from Shinprof.',
    sec2Par: 'Installation of plastic windows is very popular all over the world today. It is not only fashionable, but also convenient to use. European windows meet all international quality and hygiene standards, which allows them to be used in all types of premises. People all over the world prefer metal-plastic windows because they have many advantages.',
    sec2Stat1: 'COMFORT AT ANY TIME OF THE YEAR',
    sec2Stat2: 'NOISE PROTECTION',
    sec2Stat3: 'RELIABILITY AND SIMPLICITY',
    sec3Tit: 'Windows can afford: from xxx amd. from 19.000 amd.',
    sec3Par: 'Order a window now, and pay later! Provided by banks: ',
    sec3ParStr: 'ACBA-CREDIT AGRICOLE',
    sec4MainTit: 'HIGH QUALITY OF OUR PRODUCTS',
    sec4Tit1: 'Profile System',
    sec4par1: 'Durability, good protective properties, optimal price-quality ratio of the German xxxx profile system are best suited for the production of reliable warm windows.',
    sec4Tit2: 'Double-Glazed Window',
    sec4par2: 'A large assortment of double-glazed windows makes it possible to choose a product for individual needs and desires. Any solutions are available to you, including enhanced protection against noise, cold and summer heat.',
    sec4Tit3: 'Furniture',
    sec4par3: 'The functionality, convenience and ease of use of plastic windows depend on the quality of the hardware. We use reliable fittings of the Austrian company MACO, each element of which has increased wear resistance.',
    sec5MainTit: 'SPECIAL OFFER: LOAN OR INSTALLMENTS!',
    sec5StatTit1: 'FOR 20 MINUTES',
    sec5StatPar1: 'LOAN APPROVAL',
    sec5StatTit2: 'FROM 0%',
    sec5StatPar2: 'AN INITIAL FEE',
    sec5StatTit3: 'TIME',
    sec5StatPar3: 'UP TO 24 MONTHS',
    sec5ContTit: 'LEAVE AN APPLICATION FOR MEASUREMENT AND GET FREE CHECK OUT OF THE EXPERTS',
    sec5ContNum1: '+374-77(62-65-79)',
    sec5ContNum2: '+374-77(62-65-79)',
    sec5CallBut: 'REQUEST A CALL',
    sec6Tit: 'ACCESSORIES FOR PLASTIC WINDOWS',
    sec6SlideTit1: 'WINDOW SILLS',
    sec6SlideTit2: 'OPENING LIMITERS',
    sec6SlideTit3: 'MOSQUITO NETS',
    sec6SlideTit4: 'Lorem ipsum dolor',
    sec6SlideTit5: 'PENS',
    sec6SlideTit6 : 'Lorem ipsum dolor',
    sec6SlidePar1: 'A wide selection of materials, sizes, colors and textures for any interior and any functionality. Vandal-proof window sills with increased resistance to mechanical and chemical factors are available.',
    sec6SlidePar2: 'To ensure child safety at home and in child care facilities, we offer to equip windows with locks and opening stops. Devices can be mounted on already installed windows.',
    sec6SlidePar3: 'A huge range of nets allows you to choose the best option, taking into account the needs of the inhabitants of the house. There are nets with the function of filtering micro-pollution and pollen to maintain cleanliness and maintain the health of your family.',
    sec6SlidePar4: '',
    sec6SlidePar5: 'A large assortment of window handles allows you to choose a convenient option that suits the window and interior in color and style. The wear-resistant coating of the handle will withstand many years of intensive use and retain its original aesthetics.',
    sec6SlidePar6: '',
    sec7MainTit: 'WHY CHOOSE SHINPROF',
    sec7Tit1: 'MANAGER',
    sec7Tit2: 'FREE DEPARTURE OF A SPECIALIST',
    sec7Tit3: 'SAVING TIME',
    sec7Tit4: 'EXPERT ADVICE',
    sec7Tit5: 'DISCOUNT FOR VOLUME',
    sec7Tit6: 'INSTALLMENT PAYMENT',
    sec7Par1: 'One manager will be responsible for your project, to whom you can ask all questions.',
    sec7Par2: 'The wizard will arrive at the specified address, clarify all the details of the order and take the exact dimensions.',
    sec7Par3: 'Sign the contract and pay where it is convenient for you: in our office or at home.',
    sec7Par4: 'We will tell you all about glazing and give design recommendations.',
    sec7Par5: 'Up to xx% discount on the most interesting solutions and large-scale glazing volumes.',
    sec7Par6: 'Do not limit your possibilities: use installments for 3, 6, 12 months.',
    copyright: '©2020 All rights reserved |',
    copyrightLink: ' Digicode',
}
export default engLanguage;