var ruLanguage = {
    callButon: 'ЗАКАЗАТЬ ЗВОНОК',
    number: '+374-77(62-65-79)',
    menu1: 'МЕТАЛЛОПЛАСТИК',
    menu2: 'АЛЮМИНИЕВЫЕ',
    menu3: 'СТЕКЛЯННЫЕ КОНСТРУКЦИИ',
    marquee: 'Выгодные летние предложения от шинпроф При заказе более 10 квадратных метров в подарок: КОМПЛЕКСНЫЙ ЗАМОК ИЛИ СЕТЬ. КРЕДИТ НА РАССРОЧКУ 0% АВАНС, замер И УСТАНОВКА БЕСПЛАТНО',
    sec2Tit: 'Металлопластиковые окна от «Шинпроф».',
    sec2Par: 'Установка пластиковых окон сегодня очень популярна во всем мире. Это не только модно, но и удобно в использовании. Европейские окна соответствуют всем международным стандартам качества и гигиены, что позволяет использовать их во всех типах помещений. Люди во всем мире предпочитают металлопластиковые окна, потому что у них много преимуществ.',
    sec2Stat1: 'КОМФОРТ В ЛЮБОЕ ВРЕМЯ ГОДА',
    sec2Stat2: 'ЗАЩИТА ОТ ШУМА',
    sec2Stat3: 'НАДЁЖНОСТЬ И ПРОСТОТА',
    sec3Tit: 'Окна по карману: от 19.000 амд.',
    sec3Par: 'Закажите окно сейчас, а заплатите потом! Предоставляется банками: ',
    sec3ParStr: 'АКБА-КРЕДИТ АГРИКОЛЬ',
    sec4MainTit: 'ВЫСОКОЕ КАЧЕСТВО НАШЕЙ ПРОДУКЦИИ',
    sec4Tit1: 'Профильная Система',
    sec4par1: 'Долговечность, хорошие защитные свойства, оптимальное соотношение цены и качества немецкой профильной системы xxxx наилучшим образом подходят для производства надежных теплых окон.',
    sec4Tit2: 'Стеклопакет',
    sec4par2: 'Большой ассортимент стеклопакетов дает возможность подобрать продукт под индивидуальные нужды и желания. Вам доступны любые решения, включая усиленную защиту от шума, холода и летнего зноя.',
    sec4Tit3: 'Фурнитура',
    sec4par3: 'Функциональность, удобство и простота эксплуатации пластиковых окон зависят от качества фурнитуры. Мы используем надежную фурнитуру австрийской компании MACO, каждый элемент которой обладает повышенной износостойкостью.',
    sec5MainTit: 'CПЕЦПРЕДЛОЖЕНИЕ: КРЕДИТ ИЛИ РАССРОЧКА!',
    sec5StatTit1: 'ЗА 20 МИНУТ',
    sec5StatPar1: 'ОДОБРЕНИЕ КРЕДИТА',
    sec5StatTit2: 'ОТ 0%',
    sec5StatPar2: 'ПЕРВОНАЧАЛЬНЫЙ ВЗНОС',
    sec5StatTit3: 'ДО 24 МЕСЯЦЕВ',
    sec5StatPar3: 'СРОК',
    sec5ContTit: 'ОСТАВЬТЕ ЗАЯВКУ НА ЗАМЕР И ПОЛУЧИТЕ БЕСПЛАТНЫЙ ВЫЕЗД СПЕЦИАЛИСТА ПО ЗАМЕРАМ',
    sec5ContNum1: '+374-77(62-65-79)',
    sec5ContNum2: '+374-77(62-65-79)',
    sec5CallBut: 'ЗАКАЗАТЬ ЗВОНОК',
    sec6Tit: 'АКСЕССУАРЫ ДЛЯ ПЛАСТИКОВЫХ ОКОН',
    sec6SlideTit1: 'Подоконники',
    sec6SlideTit2: 'ОГРАНИЧИТЕЛИ ОТКРЫВАНИЯ',
    sec6SlideTit3: 'МОСКИТНЫЕ СЕТКИ',
    sec6SlideTit4: 'Lorem ipsum dolor',
    sec6SlideTit5: 'РУЧКИ',
    sec6SlideTit6 : 'Lorem ipsum dolor',
    sec6SlidePar1: 'Большой выбор материалов, размеров, цветов и фактур под любой интерьер и любую функциональность. Доступны антивандальные подоконники с повышенной устойчивостью к механическим и химическим факторам.',
    sec6SlidePar2: 'Для обеспечения детской безопасности дома и в детских учреждениях предлагаем оснастить окна замками и ограничителями открывания. Устройства можно смонтировать на уже установленные окна.',
    sec6SlidePar3: 'Огромный ассортимент сеток позволяет подобрать оптимальный вариант с учетом потребностей обитателей дома. Имеются сетки с функцией фильтрации микрозагрязнений и пыльцы для поддержания чистоты и сохранения здоровья вашей семьи.',
    sec6SlidePar4: '',
    sec6SlidePar5: 'Большой ассортимент оконных ручек позволяет подобрать удобный вариант, подходящий к окну и интерьеру по цвету и стилю. Износостойкое покрытие ручки выдержит многолетнюю интенсивную эксплуатацию и сохранит свою изначальную эстетику.',
    sec6SlidePar6: '',
    sec7MainTit: 'ПОЧЕМУ ВЫБРАТЬ ШИНПРОФ',
    sec7Tit1: 'МЕНЕДЖЕР',
    sec7Tit2: 'БЕСПЛАТНЫЙ ВЫЕЗД СПЕЦИАЛИСТА',
    sec7Tit3: 'ЭКОНОМИЯ ВРЕМЕНИ',
    sec7Tit4: 'КОНСУЛЬТАЦИИ ЭКСПЕРТОВ',
    sec7Tit5: 'СКИДКА ЗА ОБЪЕМ',
    sec7Tit6: 'ОПЛАТА В РАССРОЧКУ',
    sec7Par1: 'За ваш проект будет отвечать один менеджер, которому можно задать все вопросы.',
    sec7Par2: 'Мастер приедет по указанному адресу, уточнит все детали заказа и снимет точные размеры.',
    sec7Par3: 'Подписывайте договор и платите там, где вам удобно: у нас в офисе или у себя дома.',
    sec7Par4: 'Расскажем вам все об остеклении и дадим рекомендации по дизайну.',
    sec7Par5: 'На самые интересные решения и масштабные объемы остекления скидка до xx%.',
    sec7Par6: 'Не ограничивайте свои возможности: воспользуйтесь рассрочкой на 3, 6, 12 месяцев.',
    copyright: '©2022 Все права защищены |',
    copyrightLink: 'Digicode',
}
export default ruLanguage;